import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import "./FeatureLayout.scss";
import { Link } from "gatsby";
import { AiOutlineArrowDown } from "react-icons/ai";
import Footer from "../Footer/";
import MainLayout from "../MainLayout";

interface FeatureLayoutProps {
  id: string;
  image: string;
  title: string;
  description: string | JSX.Element;
  secondaryImage?: string;
  ctaText?: string;
  nextRoute?: string;
  nextPageButtonText?: string;
  width: number;
}

const FeatureLayout: React.FC<FeatureLayoutProps> = (props) => {
  const {
    image,
    title,
    description,
    secondaryImage,
    ctaText,
    id,
    nextRoute,
    width,
    nextPageButtonText,
  } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <MainLayout hideFooter width={width}>
      <div className="d-flex flex-column pointer-events-all flex-auto">
        <div
          id={id}
          ref={containerRef}
          className="feature-content-wrapper bg-white flex-auto"
        >
          <Container className="feature-content d-flex gap-6">
            <img className="feature-image" src={image} alt={title} />
            <div className="feature-details">
              <h1 className="mb-3">{title}</h1>
              <h5 className="mb-3">Why use it?</h5>
              <p>{description}</p>
              {secondaryImage && (
                <img
                  className="feature-image-secondary mt-4 mb-3"
                  src={secondaryImage}
                  alt={"secondary feature image"}
                />
              )}
              {ctaText && (
                <Link to="/">
                  <button className="button-primary mt-4">{ctaText}</button>
                </Link>
              )}
            </div>
          </Container>
        </div>
        {nextRoute && (
          <Link
            to={nextRoute}
            style={{
              position: "sticky",
              bottom: "0",
              left: "0",
            }}
          >
            <button
              style={{ background: "#F1F4F8" }}
              className="w-100 button-primary text-dark d-flex align-items-center justify-content-center"
            >
              <p className="m-0 mr-2">{nextPageButtonText || "Next Feature"}</p>
              <AiOutlineArrowDown />
            </button>
          </Link>
        )}
      </div>
    </MainLayout>
  );
};

export default FeatureLayout;
