import React from "react";
import FeatureLayout from "../components/FeatureLayout/FeatureLayout";
import MainLayout from "../components/MainLayout";

const feature = {
  id: "augmented-reality",
  image: "/images/showcase/feature-ar.png",
  title: "Augmented reality",
  description: (
    <p>
      Your customers can’t imagine how your product will look in their space?
      <br />
      <br />
      Just let them try it out with the power of Augmented Reality — in an
      matter of seconds.
    </p>
  ),
  ctaText: "Try it out",
  secondaryImage: "/images/showcase/cr_line.png",
  nextRoute: "/q&a",
  width: 70,
  nextPageButtonText: "Still have questions?",
};
const AugmentedReality = () => {
  return <FeatureLayout {...feature} />;
};

AugmentedReality.Layout = MainLayout;

export default AugmentedReality;
